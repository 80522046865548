import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/contactForm";

const IndexPage = (props) => (
  <Layout>
    <SEO title="Stay Ahead of the Digital Marketing Race" />

    <div className="pb-5 pt-lg-4 cloud d-vh">
      <div className="globe-container">
      
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-2 m-vh">
            <Img className="gatsby-image-wrapper" fluid={props.data.imageGlobe.childImageSharp.fluid} />
            </div>
            <div className="col-lg-6 d-flex align-items-center order-lg-1">
              <div className="mt-5">
              <h1 className="title">Be where the world is going</h1>
              <p className="sub-text text-uppercase">
                Take your digital marketing to the next level with REDHILL
              </p>
              <Link to="/#contact" className="mt-4 btn btn-danger">
                Show Me How
              </Link>
            </div>
            </div>
          </div>
        </div>
        </div>
    </div>

    <div className="py-5 tree-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="pt-m-custom mx-m-auto">
              <h2 className="title">Digital Marketing</h2>
              <p className="sub-text">is more important than ever</p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="pt-lg-5">
              <div className="card mb-3">
                <div className="row no-gutters d-flex align-items-center">
                  <div className="col-md-4">
                    <Img
                      className="icons mx-auto d-block"
                      fluid={props.data.iconReachMore.childImageSharp.fluid} 
                      alt="Reach more people"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="sub-title">Reach more people</h3>
                      <p>Deliver your message to a global audience</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="row no-gutters d-flex align-items-center">
                  <div className="col-md-4">
                    <Img
                      className="icons mx-auto d-block"
                      fluid={props.data.iconDriveLeads.childImageSharp.fluid}
                      alt="Drive leads"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="sub-title">Drive leads</h3>
                      <p>
                        Get your product or service in front of potential
                        customers
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="row no-gutters d-flex align-items-center">
                  <div className="col-md-4">
                    <Img
                      className="icons mx-auto d-block"
                      fluid={props.data.iconIncreaseSales.childImageSharp.fluid}
                      alt="Increase sales"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="sub-title">Increase sales</h3>
                      <p>Convert customers directly on your website</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-grey py-5 red-illustration">
      <div className="container text-center">
        <h2 className="title">REDHILL</h2>
        <p className="sub-text mb-4">delivers results</p>
        <div className="row">
          <div className="col-lg-4 my-lg-4">
            <Img
              className="icons mx-auto d-block"
              fluid={props.data.iconStrategy.childImageSharp.fluid}
              alt="Strategy"
            />
            <h3 className="sub-title">Strategy</h3>
            <p>Build a strategy that delivers</p>
          </div>
          <div className="col-lg-4 my-lg-4">
            <Img
              className="icons mx-auto d-block"
              fluid={props.data.iconSEO.childImageSharp.fluid}
              alt="SEO"
            />
            <h3 className="sub-title">Search Engine Optimization (SEO)</h3>
            <p>Rank on Google for the keywords that matter</p>
          </div>
          <div className="col-lg-4 my-lg-4">
            <Img
              className="icons mx-auto d-block"
              fluid={props.data.iconSEM.childImageSharp.fluid}
              alt="SEM"
            />
            <h3 className="sub-title">Search Engine Marketing (SEM)</h3>
            <p>Run ads that get clicks</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 my-lg-4">
            <Img
              className="icons mx-auto d-block"
              fluid={props.data.iconSM.childImageSharp.fluid}
              alt="Social Media &amp; Content Marketing"
            />
            <h3 className="sub-title">Social Media &amp; Content Marketing</h3>
            <p>Create content that engages</p>
          </div>
          <div className="col-lg-4 my-lg-4">
            <Img
              className="icons mx-auto d-block"
              fluid={props.data.iconEM.childImageSharp.fluid}
              alt="Email Marketing"
            />
            <h3 className="sub-title">Email Marketing</h3>
            <p>Launch email campaigns that convert</p>
          </div>
          <div className="col-lg-4 my-lg-4">
            <Img
              className="icons mx-auto d-block"
              fluid={props.data.iconWD.childImageSharp.fluid}
              alt="Web Design"
            />
            <h3 className="sub-title">Website Design &amp; Development</h3>
            <p>Refresh your online identity</p>
          </div>
        </div>
      </div>
    </div>

    <div className="py-5 tree-3">
      <div className="tree-2">
        <div className="container-fluid text-center">
          <h2 className="title">Brands</h2>
          <p className="sub-text mb-4">we work with</p>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoHelicap.childImageSharp.fluid}
                alt="Helicap"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoFatBelly.childImageSharp.fluid}
                alt="Fat Belly"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoRaise.childImageSharp.fluid}
                alt="raiSE"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoBusyBees.childImageSharp.fluid}
                alt="Busy Bees"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoPraxis.childImageSharp.fluid}
                alt="Praxis"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoSkyports.childImageSharp.fluid}
                alt="Skyports"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoFWD.childImageSharp.fluid}
                alt="FWD"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoCarlsJr.childImageSharp.fluid}
                alt="Carls Jr"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoEmirates.childImageSharp.fluid}
                alt="Emirates"
              />
            </div>
            <div className="col-lg-2 my-lg-4">
              <Img
                className="logos mx-auto d-block"
                fluid={props.data.logoVickers.childImageSharp.fluid}
                alt="Vickers Venture"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="contact" className="bg-red py-5 tree-4">
      <div className="container">
        <div className="row">
        <div className="col-lg-6 d-flex align-items-center">
          <div>
            <h2 className="title-white">Get a free consultation</h2>
            <p className="sub-text-white">
              Tell us more about your business, so we can find a solution that’s
              right for you.
            </p>
          </div>
          </div>
          <div className="col-lg-6">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    iconReachMore: file(
      relativePath: { eq: "elements/Reach_more_people_icon.png" }
    ) {
      ...fluidImage
    }
    iconDriveLeads: file(
      relativePath: { eq: "elements/drive_leads_icon.png" }
    ) {
      ...fluidImage
    }
    iconIncreaseSales: file(
      relativePath: { eq: "elements/increase_sales_icon.png" }
    ) {
      ...fluidImage
    }
    iconStrategy: file(relativePath: { eq: "elements/strategy_icon.png" }) {
      ...fluidImage
    }
    iconSEO: file(relativePath: { eq: "elements/SEO_icon.png" }) {
      ...fluidImage
    }
    iconSEM: file(relativePath: { eq: "elements/SEM_icon.png" }) {
      ...fluidImage
    }
    iconSM: file(relativePath: { eq: "elements/Social_media_icon.png" }) {
      ...fluidImage
    }
    iconEM: file(relativePath: { eq: "elements/email_marketing_icon.png" }) {
      ...fluidImage
    }
    iconWD: file(relativePath: { eq: "elements/website_design_icon.png" }) {
      ...fluidImage
    }
    logoBusyBees: file(relativePath: { eq: "logos/busybees_logo.png" }) {
      ...fluidImage
    }
    logoCarlsJr: file(relativePath: { eq: "logos/carlsjr_logo.png" }) {
      ...fluidImage
    }
    logoEmirates: file(relativePath: { eq: "logos/emirates_logo.png" }) {
      ...fluidImage
    }
    logoFatBelly: file(relativePath: { eq: "logos/fatbelly_logo.png" }) {
      ...fluidImage
    }
    logoFWD: file(relativePath: { eq: "logos/fwd_logo.png" }) {
      ...fluidImage
    }
    logoHelicap: file(relativePath: { eq: "logos/helicap_logo.png" }) {
      ...fluidImage
    }
    logoPraxis: file(relativePath: { eq: "logos/praxis_logo.png" }) {
      ...fluidImage
    }
    logoRaise: file(relativePath: { eq: "logos/raise_logo.png" }) {
      ...fluidImage
    }
    logoSkyports: file(relativePath: { eq: "logos/skyports_logo.png" }) {
      ...fluidImage
    }
    logoVickers: file(relativePath: { eq: "logos/vickers_logo.png" }) {
      ...fluidImage
    }
    imageGlobe: file(relativePath: { eq: "elements/redhill_digital_world.png" }) {
      ...fluidImage
    }
  }
`;
